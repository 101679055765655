import React from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import getAnimation from "@utils/getAnimation";

const StatsPattern = ({ fitHeight, className: _className }) => {
  const draw = getAnimation("draw");
  return (
    <span
      className={clsx("icon block", _className, {
        "icon--fit-height": fitHeight,
      })}
    >
      <m.svg
        xmlns="http://www.w3.org/2000/svg"
        width="308"
        height="791"
        viewBox="0 0 308 791"
        initial="hidden"
        whileInView="visible"
        className="stroke-2 opacity-40 sm:stroke-1 sm:opacity-100"
        viewport={{
          once: true,
        }}
      >
        <m.rect
          x="356.339"
          y="345.59"
          width="97.5357"
          height="95.9464"
          rx="9.5"
          transform="rotate(-180 356.339 345.59)"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="212.036"
          y="312.804"
          width="97.5357"
          height="95.9464"
          rx="9.5"
          transform="rotate(-180 212.036 312.804)"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M308.607 540.197V495.845C308.607 446.314 268.455 406.162 218.924 406.162C215.465 406.162 212.661 408.966 212.661 412.426V540.287C212.661 545.534 216.914 549.787 222.161 549.787H299.017C304.313 549.787 308.607 545.493 308.607 540.197Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M347.856 663.883H303.504C253.974 663.883 213.821 704.035 213.821 753.566C213.821 757.025 216.626 759.829 220.085 759.829H347.946C353.193 759.829 357.446 755.576 357.446 750.329V673.473C357.446 668.176 353.153 663.883 347.856 663.883Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M73.8746 313.215H155.536C160.782 313.215 165.036 317.469 165.036 322.715V404.376C165.036 407.897 162.182 410.751 158.661 410.751C108.314 410.751 67.5 369.937 67.5 319.59C67.5 316.069 70.354 313.215 73.8746 313.215Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M104.907 500.668H232C237.247 500.668 241.5 504.921 241.5 510.168V589.121C241.5 594.394 237.226 598.668 231.953 598.668H190.093C139.508 598.668 98.5 557.66 98.5 507.075C98.5 503.536 101.369 500.668 104.907 500.668Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M6.90698 644.666H134C139.247 644.666 143.5 648.919 143.5 654.166V733.12C143.5 738.392 139.226 742.666 133.953 742.666H92.093C41.5076 742.666 0.5 701.658 0.5 651.073C0.5 647.535 3.3685 644.666 6.90698 644.666Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M234.661 692.753H153C147.753 692.753 143.5 688.499 143.5 683.253L143.5 601.591C143.5 598.071 146.354 595.217 149.875 595.217C200.221 595.217 241.036 636.031 241.036 686.378C241.036 689.899 238.182 692.753 234.661 692.753Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M116.5 127.142V90.1823C116.5 40.6519 156.652 0.499588 206.183 0.499588C209.642 0.499588 212.446 3.30394 212.446 6.76329V125.089C212.446 130.336 208.193 134.589 202.946 134.589H123.946C119.834 134.589 116.5 131.255 116.5 127.142Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M308.393 41.9971L308.393 245.113C308.393 247.386 306.55 249.229 304.276 249.229C253.56 249.229 212.446 208.116 212.446 157.399L212.446 41.9971C212.446 36.7504 216.699 32.4971 221.946 32.4971L298.893 32.4971C304.139 32.4971 308.393 36.7504 308.393 41.9971Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M261.5 769.166V898.547C261.5 900.822 263.344 902.666 265.619 902.666C316.363 902.666 357.5 861.53 357.5 810.785V766.023C357.5 762.512 354.654 759.666 351.143 759.666H271C265.753 759.666 261.5 763.919 261.5 769.166Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M299.071 409.161H254.719C205.188 409.161 165.036 369.009 165.036 319.478C165.036 316.019 167.84 313.215 171.3 313.215H299.161C304.408 313.215 308.661 317.468 308.661 322.715V399.571C308.661 404.868 304.367 409.161 299.071 409.161Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="242.071"
          y="647.305"
          width="97.5357"
          height="95.9464"
          rx="9.5"
          transform="rotate(-90 242.071 647.305)"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.rect
          x="117.554"
          y="790.666"
          width="97.5357"
          height="95.9464"
          rx="9.5"
          transform="rotate(-90 117.554 790.666)"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M26.8824 120.719L72.8172 120.719C122.348 120.719 162.5 160.871 162.5 210.401C162.5 213.861 159.696 216.665 156.236 216.665L28.375 216.665C23.1283 216.665 18.875 212.412 18.875 207.165L18.875 128.726C18.875 124.304 22.46 120.719 26.8824 120.719Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
      </m.svg>
    </span>
  );
};

StatsPattern.defaultProps = {};

export default StatsPattern;
