import React from "react";
import { Container, Button, Text } from "@atoms";
import { Heading, Stat } from "@molecules";
import clsx from "clsx";
import StatsPattern from "@svg/StatsPattern";

const StatsOverview = ({ heading, descriptor, stats, button }) => {
  const calcColor = i => {
    if ((i + 1) % 3 === 0) return "purple";
    if ((i + 1) % 3 === 2) return "pink";
    if ((i + 1) % 3 === 1) return "teal";
    return "teal";
  };
  return (
    <section
      className={clsx({
        "my-10 sm:my-20": heading,
        "mb-10 sm:mb-20": !heading,
      })}
    >
      <div className="pointer-events-none absolute right-0 -z-50 h-[900px] -translate-y-24 translate-x-[40%] transform overflow-hidden">
        <StatsPattern
          fitHeight
          className="h-full w-80 text-teal md:w-[23.125rem]"
        />
      </div>
      <Container padding className="relative z-10 text-center">
        <Heading heading={heading} description={descriptor} />
        {stats.length > 0 && (
          <div className="mb-8 mt-6 flex flex-wrap">
            {stats?.map((stat, i) => {
              return <Stat {...stat} color={calcColor(i)} />;
            })}
          </div>
        )}
        {button?.url && (
          <Button to={button?.url} color="purple" size="md">
            <Text>{button?.text}</Text>
          </Button>
        )}
      </Container>
    </section>
  );
};

StatsOverview.defaultProps = {};

export default StatsOverview;
